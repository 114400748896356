import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

const coins = [
  "tether",
  "usd-coin",
  "binance-usd",
  "terrausd",
  "dai",
  "magic-internet-money",
  "frax",
  "true-usd",
  "paxos-standard",
  "neutrino",
];

const title = "Stablecoins";
const description = `Stablecoins (also Stabile Münzen) sind digitale Währungen, die einen bestimmten Vermögenswert
abbilden. Das können sowohl Fiat-Währungen, wie zum Beispiel US-Dollar, Euro, britisches Pfund, als auch Edelmetalle wie Gold sein.`
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={[title]} title={title} description={description} />
      <CategoryTemplate title={title} coins={coins} description={description} coingeckoId="stablecoins" />
    </Layout>
  );
};

export default Page;
